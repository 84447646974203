import React, { useState, useEffect, useContext } from 'react'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import { mapEdgesToNodes } from '../lib/helpers'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { css } from '@emotion/core'
import _ from 'lodash'
import Wrapper from '../components/HorizontalScroll/Wrapper'
import Container from '../components/HorizontalScroll/Container'
import HeaderContainer from '../components/HorizontalScroll/HeaderContainer'
import H1 from '../components/Headers/H1'
import ServiceItem from '../components/modules/serviceItem'
import SidewaysContainer from '../components/HorizontalScroll/SidewaysContainer'
import { pStyle } from '../styles/mixins'
import theme from '../styles/theme'
import {AppContext} from "../context/stateProvider"
import HorizontalScroll from '@oberon-amsterdam/horizontal';
import { HorizontalLine } from "./locations"

export const query = graphql`
  query AboutPageQuery {
    page: sanityPageTemplate(_id: { eq: "5c14596e-68e9-4c14-a8d7-c27254255356" }) {
      title
      header
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
    service: allSanityServices(sort: {fields: order, order: ASC}) {
      edges {
        node {
          title
          description
          mainImage {
            asset {
              _id
              fluid(maxHeight: 700) {
                ...GatsbySanityImageFluid
              }
              title
              description
              assetId
            }
            caption
            alt
          }
          order
        }
      }
    }
  }
`

const AboutPage = (props) => {
  const { data, errors } = props
  const [container, setContainer] = useState()
  const { appState, setAppState } = useContext(AppContext)


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const servicesNodes = (data || {}).service ? mapEdgesToNodes(data.service) : []
  const heading = _.get(data, 'page.header')

  const page = (data || {}).page
  if (!page) {
    throw new Error(
      'Missing "About" page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }



    useEffect(() => {
        const scrollContainer = document.querySelector('.container')
        console.log("isMobile", appState.isMobile)
        if (appState.isMobile === false) {
          new HorizontalScroll({ container: scrollContainer });
        }
    }, [appState]);


  return (
    <Layout darkMode>
      <SEO title={page.title} />
      <Container>
        <Wrapper
          className="container"
        >
          <HeaderContainer>
            <H1>{heading}</H1>
            <P>
              We're a full-service wedding and event planning company that handles every aspect of
              your memorable day. As we say, we offer our clients "Smiles from different angles."
            </P>
          </HeaderContainer>
          <SidewaysContainer>
          <HorizontalLine />
            {servicesNodes &&
              servicesNodes.map((node, i) => {
                return <ServiceItem service={node} key={i} />
              })}
          </SidewaysContainer>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default AboutPage

export const P = styled.div`
  ${pStyle}
  color:${theme.colors.white};
  margin-top: 50px;
  text-align: left;
`
