import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import {css} from "@emotion/core"
import { mapEdgesToNodes } from '../lib/helpers'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import LocationGalleryItem from '../components/modules/locationGalleryItem'
import GraphQLErrorList from '../components/graphql-error-list'
import { bigHeader, MaxWidthContainer } from '../styles/mixins'
import theme from '../styles/theme'
import useHorizontal from '@oberon-amsterdam/horizontal/hook'
import Wrapper from "../components/HorizontalScroll/Wrapper"
import Container from "../components/HorizontalScroll/Container"
import HeaderContainer from "../components/HorizontalScroll/HeaderContainer"
import SidewaysContainer from "../components/HorizontalScroll/SidewaysContainer"
import H1 from "../components/Headers/H1"
import { P } from "./about"
import {AppContext} from "../context/stateProvider"
import HorizontalScroll from '@oberon-amsterdam/horizontal';

export const query = graphql`
  query LocationsPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPageTemplate(_id: { eq: "9196bef7-0917-4e14-93ac-6fe6c01c8242" }) {
      title
      header
    }
    locations: allSanityLocation {
      edges {
        node {
          title
          slug {
            current
          }
          body {
            _key
            _type
            style
            list
          }
          _id
          _rawBody
          icon {
            asset {
              url
              _id
            }
          }
          gallery {
            asset {
              _id
              assetId
              fluid {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    }
  }
`

const LocationsPage = props => {
  const [container, setContainer] = useState()
  const { appState, setAppState } = useContext(AppContext)

  const { data, error } = props
  // console.log("locations data: ", data)

  if (error) {
    return (
      <Layout darkMode={true}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const locationNodes = (data || {}).locations ? mapEdgesToNodes(data.locations) : []

  const site = (data || {}).site
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  const heading = _.get(data, 'page.header')
  // console.log("Location Nodes: ", locationNodes)

  useEffect(() => {
      const scrollContainer = document.querySelector('.container')
      console.log("isMobile", appState.isMobile)
      if (appState.isMobile === false) {
        new HorizontalScroll({ container: scrollContainer });
      }
  }, [appState]);



  return (
    <Layout darkMode={true}>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <Wrapper
          className="container"
        >
          <HeaderContainer>
            <H1 css={css `padding-right: 30vh;`}>{heading}</H1>
            <P>
              Carefully curated luxury venues across the U.S.
            </P>
          </HeaderContainer>
          <SidewaysContainer>
            <HorizontalLine />
            {locationNodes &&
              locationNodes.map((node, i) => {
                //console.log('node: ', node)
                return (
                  <>
                    <GalleryContainer>
                      <LocationGalleryItem location={node} key={i} />
                    </GalleryContainer>
                  </>
                )
              })}
          </SidewaysContainer>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default LocationsPage


const GalleryContainer = styled.div`


	@media(min-width:${theme.breakpoints.navMobile}) {
   margin-right: 40px;
    padding-right: 30vh;
	}
`

export const HorizontalLine = styled.div`
  position: absolute;
  opacity: 0.18;
  background: ${theme.colors.white};
  left: 50%;
  height: 100%;
  width: 1px;

	@media(min-width:${theme.breakpoints.navMobile}) {
    top: 50%;
    width: 100%;
    height: 1px;
    left: 0;
	}
`
