import React, { useState, useRef } from 'react'
import Img from 'gatsby-image'
import _ from 'lodash'
import styled from '@emotion/styled'
import theme from '../../styles/theme'
import { css } from '@emotion/core'
import BlockContent from '../block-content'
import {  subheader, pStyle } from '../../styles/mixins'

const ServiceItem = ({service}) => {
return (
    <>  
      <Container className={service.mainImage ? "has-image": "no-image"}>
          <Item>
              <H2>{service.title}</H2>
              <P>{service.description}</P>
          </Item>
          {service.mainImage &&
            <ImgContainer className="has-image">
              <Img fluid={service.mainImage.asset.fluid} alt={service.mainImage.alt} />
            </ImgContainer>
          }
      </Container>

    </>
  )
}

export default ServiceItem

const Container = styled.div `
  display: flex;
  align-items: center;
  flex-direction: column;

	@media(min-width:${theme.breakpoints.small}) {
   flex-direction: row;

    &:last-of-type {
      margin-right: 10rem;
    }
	}

`
const Item = styled.div`
  border: solid 1px ${theme.colors.white};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${theme.colors.black};
  margin-bottom: 3rem;

	@media(min-width:${theme.breakpoints.small}) {
    width: 30rem;
    min-height: 10vh;
    margin-right: 10rem;
    padding: 24vh 3rem;
    margin-bottom: 0;
	}

`
const H2 = styled.h2`
  ${subheader}
  text-align: left;
  color: ${theme.colors.white};
`
const P = styled.div`
	${pStyle}
	color:${theme.colors.white};
	margin-top:20px;
	text-align: left;
`
const ImgContainer = styled.div `
	display:block;
	width: 100%;
  height: 100%;
  margin-bottom: 3rem;

	@media(min-width:${theme.breakpoints.small}) {
   	width: 115vh;
    height: 100vh;
    margin-right: 10rem;
    margin-bottom: 0;
	}
`
